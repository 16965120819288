import HonorataKlimek from "../../assets/people/MFF_6766.jpg";
import EwaOrzeszyna from "../../assets/people/MFF_9401.jpg";
import BeataWolna from "../../assets/people/MFF_6974.jpg";
import AndrzejMaterak from "../../assets/people/MFF_7011.jpg";
import DariuszBrys from "../../assets/people/MFF_7049.jpg";
import JaroslawMarchewka from "../../assets/people/MFF_7075.jpg";
import KarolinaWilk from "../../assets/people/MFF_7264.jpg";
import AnetaSzczesnaKalwak from "../../assets/people/MFF_9361.jpg";
import DamianSowa from "../../assets/people/MFF_9340.jpg";
import RenataWozniak from "../../assets/people/MFF_7784.jpg";
import DamianTroczka from "../../assets/people/MFF_7090.jpg";
import MiroslawWozny from "../../assets/people/MFF_7116.jpg";
import KatarzynaWojtal from "../../assets/people/MFF_7164.jpg";

export const persons = [
  {
    img: DamianSowa,
    title: "Cieciułów, Brzeziny/Stawki Cieciułowskie",
    name: "Damian Sowa",
    id: "damian-sowa",
    description: {
      ageAndEdu:
        "43 lata, mąż i tata Zosi i Marysi. Wykształcenie wyższe, nauczyciel WF-u.",
      personal:
        "Dusza sportowca, który biega codziennie dziesięć kilometrów – nie tylko w myślach :) Wspinacz górski z sercem na szklaku, zmierzający zawsze ku szczytowi… A że „wędrówką jedną życie jest człowieka”, warto podążać ku nowej, pięknej i nowoczesnej Wspólnej Gminie.",
      achievementsQuestion: "Gdzie Państwo mogli mnie zobaczyć?",
      achievements: [
        {
          desc: "byłem współorganizatorem wielu wydarzeń o zasięgu gminnym, powiatowym  i krajowym (np. Pętla Rudnicka, I Turniej Sołectw w Piłce Siatkowej o Puchar Wójta Gminy Rudniki, bieg nocny na 5km, rajd rowerowy);",
          subdesc: [],
        },
        {
          desc: "widząc potrzebę wyrównania szans dzieci w naszej Gminie, bardzo aktywnie i skutecznie starałem się o budowę nowoczesnej sali gimnastycznej i rozbudowę szkoły w Cieciułowie;",
          subdesc: [],
        },
        {
          desc: "z sukcesem zabiegałem o utworzenie parkingu przy szkole;",
          subdesc: [],
        },
        {
          desc: "jako radny w kadencji 2018–2024 wnioskowałem o przebudowę drogi powiatowej w Cieciułowie oraz budowę nowej drogi gminnej Cieciułów– Jaworzno;",
          subdesc: [],
        },
        {
          desc: "dołożyłem wszelkich starań o wspófinansowanie przez Gminę Rudniki budowy drogi łączącej Brzeziny Cieciułowskie i Szarki;",
          subdesc: [],
        },
        {
          desc: "z wielkim zaangażowaniem działałem by Sołectwo Cieciułów zyskało kilkanaście nowoczesnych lamp hybrydowych.",
          subdesc: [],
        },
      ],
      futurePlans: "Moje cele na kolejną kadencję:",
      plans: [
        {
          desc: "zwiększenie bezpieczeństwa mieszkańców to mój priorytet i dlatego będę zabiegał o zakup nowego samochodu bojowego dla OSP Cieciułów;",
          subdesc: [],
        },
        {
          desc: "widzę potrzebę zagospodarowania terenu przy OSP i utworzenie tam integracyjnego miejsca relaksu dla naszej wspólnoty;",
          subdesc: [],
        },
        {
          desc: "aktywnie wesprę wszelkie projekty podnoszące jakość infrastruktury drogowej, w tym:",
          subdesc: [
            "remont i doświetlenie  drogi gminnej Cieciułów - Brzeziny Cieciułowskie oraz uzupełnienie oświetlenia w Cieciułowie, Stawkach i Brzezinach Cieciułowskich",
            "budowa chodnika z Cieciułowa do Stawek Cieciułowskich;",
            "remont dróg powiatowych z Cieciułowa do Żytniowa oraz do Rudnik;",
          ],
        },
        {
          desc: "wykorzystując potencjał wybudowanej sali gimnastycznej rekomenduję uruchomienie dodatkowych, nieodpłatnych zajęć sportowych dla dzieci oraz dorosłych.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Głos na mnie to głos na perspektywę dobrego życia w pięknej i nowoczesnej Gminie.",
    },
  },
  {
    img: MiroslawWozny,
    title: "Bobrowa",
    name: "Mirosław Woźny",
    id: "miroslaw-wozny",
    description: {
      ageAndEdu:
        "61 lat, żonaty, tata Moniki, Adama i Tomka, szczęśliwy dziadek. Od 32 lat lokalny przedsiębiorca.",
      personal:
        "Pasjonat lasu, który zęby bobra zamienił na zęby pilarki i wraz z kolegami druhami zainicjowali męskie cięcie… Międzynarodowe Zawody Drwali– oczko w głowie radnego z Bobrowy.",
      achievementsQuestion: "Jakie są rezultaty moich dotychczasowych działań?",
      achievements: [
        {
          desc: "jako długoletni prezes Ochotniczej Straży Pożarnej w Bobrowie aktywnie działałem dla lokalnej społeczności;",
          subdesc: [],
        },
        {
          desc: "współpracowałem z tutejszym Kołem Gospodyń, jak również z Radą Sołecką i innymi organizacjami lokalnymi;",
          subdesc: [],
        },
        {
          desc: "udało mi się zrealizować wiele przedsięwzięć poprawiających jakość życia mieszkańców, m.in. utworzenie świetlicy wiejskiej dla tutejszej młodzieży, doświetlenie i doposażenie placu zabaw, zamontowanie instalacji fotowoltaicznej na Chacie Drwala;",
          subdesc: [],
        },
        {
          desc: "mając na uwadze coroczne zawody drwali oraz przyjezdnych gości, zabiegałem o poprawę estetyki sceny i jej otoczenia (w trakcie realizacji);",
          subdesc: [],
        },
        {
          desc: "jako członek komisji ds. Oświaty, Kultury, Sportu, Zdrowia i Spraw Socjalnych czynnie wsparłem uruchomienie dowozów dzieci do szkół;",
          subdesc: [],
        },
        {
          desc: "aktywnie i z sukcesem zabiegałem o budowę sali gimnastycznej w szkole podstawowej w Cieciułowie – dziś to wizytówka naszej Gminy.",
          subdesc: [],
        },
      ],
      futurePlans: "Jakie mam plany na następną kadencję?",
      plans: [
        {
          desc: "priorytetowo zamierzam zabiegać o poprawę infrastruktury drogowej: nowa nawierzchnia na drodze powiatowej, rozbudowa skrzyżowania oraz budowa chodnika;",
          subdesc: [],
        },
        {
          desc: "chciałbym kontynuować współpracę z lokalnymi organizacjami na rzecz integracji mieszkańców i pielęgnowania lokalnych zwyczajów;",
          subdesc: [],
        },
        {
          desc: "odświeżenie sali OSP to konieczność aby zachęcić do organizacji prywatnych imprez okolicznościowych;",
          subdesc: [],
        },
        {
          desc: "będę promował Bobrowę poprzez podtrzymywanie ciągłości tradycji i wydarzeń, w tym Międzynarodowych Zawodów Drwali.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Głosując na mnie stawiacie Państwo na... solidność i odpowiedzialność.",
    },
  },
  {
    img: AndrzejMaterak,
    title:
      "Żytniów 36-179, Bliźniaki, Ignachy, Kuźnica Żytniowska, Wytoka, Jelonki",
    name: "Andrzej Materak",
    id: "andrzej-materak",
    description: {
      ageAndEdu:
        "52 lata, wykształcenie wyższe, z zawodu – policjant, prywatnie – mąż oraz tata Laury i Filipa.",
      personal:
        "Smakosz muffinek (ale tylko tych, które upieką żona i Panie z Koła Gospodyń Domowych :), obecnie kandydat na radnego w nadchodzącej kadencji w Gminie Rudniki. Z sercem od zawsze w Żytniowie.",
      achievementsQuestion: "Co dla Państwa zrobiłem w mijającej kadencji?",
      achievements: [
        {
          desc: "z pozytywnym skutkiem zabiegałem o przeprowadzenie remontu drogi powiatowej biegnącej przez Żytniów – w 2022 r. inwestycja została w części zrealizowana a w br. przewidziana jest jej kontynuacja;",
          subdesc: [],
        },
        {
          desc: "współorganizowałem coroczne festyny rodzinne dla lokalnej społeczności (wspólnie z OSP Żytniów, LZS Żytniów);",
          subdesc: [],
        },
        {
          desc: "z Sekcją SET-MEN Żytniów współorganizowałem turnieje tenisa stołowego;",
          subdesc: [],
        },
        {
          desc: "pomagałem w organizacji i uczestniczyłem w spotkaniach integrujących naszą społeczność: Dzień Kobiet, Sołtysiady;",
          subdesc: [],
        },
        {
          desc: "aktywnie i społecznie pracowałem w parku Zacisze Żytniowskie oraz porządkowałem teren przed cmentarzem parafialnym oraz boisko LZS Żytniów.",
          subdesc: [],
        },
      ],
      futurePlans: "Co chciałbym dla Państwa zrobić w przyszłości?",
      plans: [
        {
          desc: "poczynię starania o remont kolejnego odcinka drogi powiatowej, biegnącej przez Żytniów w kierunku Cieciułowa;",
          subdesc: [],
        },
        {
          desc: "niezbędna jest modernizacja budynku strażnicy wiejskiej w Żyniowie (szczególnie dach oraz toalety);",
          subdesc: [],
        },
        {
          desc: "będę zabiegał o zagospodarowanie terenu po byłym boisku sportowym obok RSP Żytniów oraz o remont szatni LZS Żytniów.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Bezpieczeństwo i wysoka jakość komunikacji w naszej gminie to moje priorytety.",
    },
  },
  {
    img: HonorataKlimek,
    title: "Młyny, Łazy",
    name: "Honorata Klimek",
    id: "honorata-klimek",
    description: {
      ageAndEdu:
        "49 lat, żona i mama Gabrysi i Bartka, od 2018 r. radna miejscowości Młyny i Łazy.",
      personal:
        "Skryta miłośniczka…aromatycznej kawy i skąpanego w słońcu, urokliwie ukrytego wśród pól i drzew młyńskiego zalewu. Otwarta na przyszłość...",
      achievementsQuestion: "Jakie są rezultaty moich dotychczasowych działań?",
      achievements: [
        {
          desc: "zabiegałam o asfaltową nawierzchnię odcinków dróg:",
          subdesc: [
            "Młyny – Odcinek (przez tzw. „stary zalew”);",
            "Młyny – Rudniki (tzw. „głęboka droga” oraz droga przy ośrodku zdrowia);",
            "Z pozytywnym skutkiem zabiegałam o budowę drogi Młyny – Słowików (przy „nowym zalewie” – etap II inwestycji już w 2024 r.);",
          ],
        },
        {
          desc: "w czasie mojej kadencji zainstalowano oświetlenie przystanku autobusowego na drodze krajowej nr 43 w kierunku Wielunia oraz założono lampy hybrydowe na skrzyżowaniach dróg i częściowo doświetlono też drogę Łazy – Rudniki;",
          subdesc: [],
        },
        {
          desc: "największym sukcesem jest jednak aktualna budowa kanalizacji sanitarnej w Młynach (w Łazach została już ukończona). Całkowite zakończenie prac planowane jest na wiosnę 2024r.",
          subdesc: [],
        },
      ],
      futurePlans: "A co w przyszłości?",
      plans: [
        {
          desc: "będę zabiegała o oświetlenie drogi Rudniki – Młyny (tzw. „trakt”);",
          subdesc: [],
        },
        {
          desc: "za punkt honoru stawiam sobie budowę ciągu pieszo-rowerowego Dalachów – Rudniki (przy drodze krajowej nr 43);",
          subdesc: [],
        },
        {
          desc: "dołożę wszelkich starań, aby urzeczywistniona została największa inwestycja planowana na zbliżającą się kadencję, tj. budowa amfiteatru przy „nowym zalewie”;",
          subdesc: [],
        },
        {
          desc: "aktywnie wesprę działania prowadzące do wykupienia gruntu, na którym znajduje się urokliwy wapiennik – obowiązkowy punkt programu każdego miejscowego i przyjezdnego turysty.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego warto na mnie oddać głos?",
      slogan:
        "Stawiam na bezpieczeństwo, integrację i wypoczynek naszej wspólnoty z wykorzystaniem piękna i możliwości otaczającej natury.",
    },
  },
  {
    img: DariuszBrys,
    title: "Chwiły, Faustianka, Kuźnica, Porąbki, Jaworek",
    name: "Dariusz Bryś",
    id: "dariusz-brys",
    description: {
      ageAndEdu:
        "45 lat, mieszkaniec Faustianki. Z wykształcenia leśnik i zootechnik. Zawodowo związany z Nadleśnictwem Przedborów.",
      personal:
        "Szczęśliwie żonaty, tata siedmioletniego Franciszka. Z pasją gra amatorsko w piłkę, hodowca z sercem. Wielki miłośnik psów.",
      achievementsQuestion: "Gdzie Państwo mogli mnie zobaczyć?",
      achievements: [
        {
          desc: "od 10 lat społecznie pełnię funkcję Prezesa Zarządu Koła Łowieckiego Bażant w Praszce, gdzie stawiam na nowatorstwo działań;",
          subdesc: [],
        },
        {
          desc: "jestem aktywnym członkiem OSP Chwiły i dwukrotnie pozyskałem dofinansowanie na zakup sprzętu dla tej jednostki;",
          subdesc: [],
        },
        {
          desc: "prowadziłem zajęcia edukacyjne dla dzieci i młodzieży w szkole i przedszkolu w Rudnikach;",
          subdesc: [],
        },
        {
          desc: "współorganizowałem festyny integracyjne dla mieszkańców Faustianki;",
          subdesc: [],
        },
        {
          desc: "uczestniczyłem w spotkaniach inicjujących powstanie LGD Górna Prosna.",
          subdesc: [],
        },
      ],
      futurePlans: "Co chciałbym dla Państwa zrobić?",
      plans: [
        {
          desc: "Faustianka:",
          subdesc: [
            "będę zabiegał o rozwiązanie problemu dróg dojazdowych do posesji, transportu rolniczego, przeprowadzenie melioracji rowu gminnego oraz budowę ścieżki rowerowej;",
            "poczynię starania o realizację założeń przyjętych na zagospodarowanie nowego placu wiejskiego oraz terenu cegielni;",
            "priorytetem będzie aktywne wsparcie działań w zakresie rewitalizacji i zagospodarowania zakupionego terenu po byłej cegielni w Faustiance.",
          ],
        },
        {
          desc: "Chwiły: aktywnie wesprę działania OSP Chwiły, w tym ostateczne rozwiązanie kwestii własnościowej działki, na której położona jest strażnica;",
          subdesc: [],
        },
        {
          desc: "Porąbki: będę dążył do budowy ścieżki rowerowej oraz przeprowadzenia melioracji;",
          subdesc: [],
        },
        {
          desc: "Kuźnica:",
          subdesc: [
            "będę zabiegał o budowę wiaty przystankowej oraz znalezienie działki na lokalizację placu wiejskiego;",
            "ważna jest dla mnie kwestia doświetlenia dojazdów do posesji;",
            "będę monitował remont drogi powiatowej;",
          ],
        },
        {
          desc: "Jaworek: spróbuję rozwiązać problem lokalizacji placu wiejskiego oraz aktywnie wesprę budowę punktu widokowego (wieży) do obserwacji ptaków nad zbiornikiem w Jaworku.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Głos oddany na mnie to głos na... przemyślany rozwój gminnej infrastruktury i wygodę codziennego życia.",
    },
  },
  {
    img: JaroslawMarchewka,
    title: "Rudniki, Teodorówka",
    name: "Jarosław Marchewka",
    id: "jaroslaw-marchewka",
    description: {
      ageAndEdu:
        "58 lat, mieszkaniec Rudnik od urodzenia. Wykształcenie wyższe, nauczyciel historii, geografii i wiedzy o społeczeństwie w Publicznej Szkole Podstawowej im. A. Wajdy w Rudnikach.",
      personal:
        "Nieprzerwanie od 1998 r. Radny Gminy Rudniki, a w mijającej kadencji Przewodniczący Rady Gminy. Członek Wieluńskiego Towarzystwa Naukowego. Miłośnik szachów.Podróżnik.",
      achievementsQuestion: "Co dla Państwa zrobiłem w mijającej kadencji?",
      achievements: [
        {
          desc: "z pozytywnym skutkiem zabiegałem m.in. o:",
          subdesc: [
            "budowę jedynej na okolicę tężni solankowej;",
            "umocnienie kanału przy ulicy Reymonta;",
            "budowę studni głębinowej;",
            "założenie paneli fotowoltaicznych na budynkach użyteczności publicznych;",
            "modernizację placu zabaw i pomp ciepła przy PSP im. A. Wajdy w Rudnikach;",
            "remont ulic Handlowej i Żeromskiego w Rudnikach;",
            "oświetlenie lampami solarnymi i hybrydowymi.",
          ],
        },
        {
          desc: "Zainicjowałem wprowadzenie dodatkowej nagrody dla najlepszych uczniów z terenu Gminy Rudniki: wycieczkę do ważnych miejsc w Polsce.",
          subdesc: [],
        },
      ],
      futurePlans: "Jakie są moje plany na przyszłą kadencję?",
      plans: [
        {
          desc: "będę aktywnie starał się o:",
          subdesc: [
            "remont budynku gminnego na potrzeby GOKSiR-u i budowę parkingu przy ulicy Targowej;",
            "zakup samochodu dla OSP w Rudnikach;",
            "zagospodarowanie terenu przy powstającym Dziennym Domu Opieki;",
            "remont stołówki szkolnej;",
            "modernizację trawiastego boiska sportowego (oświetlenie);",
            "remonty kolejnych ulic i oświetlenia w Rudnikach;",
            "nowoczesną oczyszczalnię ścieków;",
            "stacjonowanie karetki pogotowia w Rudnikach.",
          ],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Głos oddany na mnie to głos na… sprawdzonego wieloletniego społecznika i rozwój naszej Gminy.",
    },
  },
  {
    img: KarolinaWilk,
    title: "Rudniki",
    name: "Karolina Wilk",
    id: "karolina-wilk",
    description: {
      ageAndEdu:
        "43 lata, żona i mama dwóch synków: Leonka i Tymonka. Wykształcenie wyższe, zawodowo związana z handlem.",
      personal:
        "Ceniąca sielskość życia w Rudnikach. Podjadanie pomidorów i ogórków prosto z ogrodu to zastrzyk świetnej energii na cały dzień. Jeśli dodać do tego szczyptę książek George'a Martina czy Camilli Lackberg na hamaku wśród zieleni, to nic nie stoi na przeszkodzie, żeby wykrzyczeć, iż życie jest po prostu piękne…",
      achievementsQuestion: "Gdzie Państwo mogli mnie zobaczyć?",
      achievements: [
        {
          desc: "założyłam i współprowadziłam FreeShop - pierwszy w naszej Gminie, bezpłatny sklep dla obywateli Ukrainy, którzy otrzymywali pomoc, wsparcie i przyjaźń;",
          subdesc: [],
        },
        {
          desc: "jako przewodnicząca Rady Rodziców aktywnie współpracuję z Publicznym Przedszkolem w Rudnikach, co daje mi mnóstwo radości i satysfakcji;",
          subdesc: [],
        },
      ],
      futurePlans: "Czym mogę Państwa zaskoczyć w przyszłości?",
      plans: [
        {
          desc: "moim marzeniem jest stworzenie w Rudnikach klubu - świetlicy dla dzieci i seniorów, miejsca, gdzie młodzi i dorośli mogliby spędzać wspólnie czas;",
          subdesc: [],
        },
        {
          desc: "chciałabym również zająć się programem aktywizującym zawodowo młode mamy do pracy online z domu;",
          subdesc: [],
        },
        {
          desc: "bardzo zależy mi na wdrożeniu programu dofinansowań do paneli fotowoltaicznych dla gospodarstw domowych;",
          subdesc: [],
        },
        {
          desc: "jako radna będę pilne wsłuchiwała się w potrzeby i troski, ale też pomysły i inicjatywy mieszkańców.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego warto na mnie zagłosować?",
      slogan:
        "Bo chcę, żeby ludzie uśmiechali się, mieszkając właśnie tutaj... w Rudnikach.",
    },
  },
  {
    img: BeataWolna,
    title: "Odcinek, Janinów",
    name: "Beata Wolna",
    id: "beata-wolna",
    description: {
      ageAndEdu:
        "48 lat, żona, mama Bartosza i Jakuba, od urodzenia mieszkanka i na zawsze wierna fanka Odcinka. Sprzedawczyni na okolicznych targowiskach.",
      personal:
        "Pasjonatka, wyjątkowa…pszczelarka, która w miłości do pszczół znalazła przepis na siebie.",
      achievementsQuestion: "W czym Państwu pomagałam?",
      achievements: [
        {
          desc: "współorganizowałam lokalne uroczystości: dożynki, festyny, akcje charytatywne;",
          subdesc: [],
        },
        {
          desc: "aktywnie i społecznie pracowałam na placu sołeckim;",
          subdesc: [],
        },
        {
          desc: "doradzałam w istotnych sprawach dotyczących Odcinka.",
          subdesc: [],
        },
      ],
      futurePlans: "A co w przyszłości?",
      plans: [
        {
          desc: "stawiam na ścisłą współpracę z moimi wyborcami, bo we wspólnym działaniu tkwi ogromny potencjał;",
          subdesc: [],
        },
        {
          desc: "będę zabiegała o rozwój infrastruktury w moim okręgu wyborczym – poprawa stanu dróg i oświetlenia (w szczególności droga Odcinek „za szkołą”);",
          subdesc: [],
        },
        {
          desc: "dołożę wszelkich starań, aby zbudować w Odcinku nowoczesny plac zabaw dla dzieci;",
          subdesc: [],
        },
        {
          desc: "zależy mi na szerszym wykorzystaniu funkcjonującego w naszej Gminie zasobu obiektów poprzez rozszerzenie oferty kulturalnej tak, by mogli z nich korzystać wszyscy mieszkańcy, np. organizacja różnych zajęć warsztatowo - szkoleniowych lub takich o charakterze pro zawodowym.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Priorytety w mojej ofercie to… pielęgnowanie więzi w naszej wspólnocie, szacunek dla tradycji, podążanie za nowoczesnością.",
    },
  },
  {
    img: EwaOrzeszyna,
    title: "Dalachów 1-215a",
    name: "Ewa Orzeszyna",
    id: "ewa-orzeszyna",
    description: {
      ageAndEdu:
        "56 lat, mężatka, mama dwóch dorosłych córek: Wiktorii i Marty, najszczęśliwsza babcia. Wykształcenie wyższe – nauczycielka.",
      personal:
        "Dalachowianka od urodzenia i radna Gminy Rudniki w latach 2018 – 2024.Swoje serce oddała…pieczeniu ciast, szczególnie tych dla potrzebujących. Pragnąca zmienić świat przez zmianę samej siebie.",
      achievementsQuestion: "Jakie są rezultaty moich dotychczasowych działań?",
      achievements: [
        {
          desc: "jako radna wspierałam inicjatywy mające na celu poprawę infrastruktury Dalachowa:",
          subdesc: [
            "budowa chodników oraz remont drogi powiatowej;",
            "modernizacja szkolnego placu zabaw dla dzieci a także sali OSP Dalachów;",
          ],
        },
        {
          desc: "jestem współzałożycielką organizacji pozarządowej Koła Gospodyń Wiejskich, która stawia na integrację mieszkańców i pielęgnowanie tradycji lokalnych: pikniki rodzinne, rajdy, kino pod chmurką, dożynki, wycieczki, konkursy kulinarne, warsztaty, mikołajki, zajęcia sportowe na Orliku, konkursy wiedzy o naszej miejscowości. Wspólnie nieśliśmy pomoc współorganizując 7 akcji charytatywnych;",
          subdesc: [],
        },
        {
          desc: "z pozytywnym skutkiem działałam w zakresie powstania w Dalachowie nadajnika sygnału operatora komórkowego;",
          subdesc: [],
        },
        {
          desc: "ściśle współpracuję z istniejącymi organizacjami na terenie naszej miejscowości, co m.in. zaowocowało utworzeniem miejsca spotkań mieszkańców, tzw. Małą Salką.",
          subdesc: [],
        },
      ],
      futurePlans: "Co planuję dla Państwa zrobić w przyszłej kadencji?",
      plans: [
        {
          desc: "jeżeli dane mi będzie po raz drugi otrzymać mandat radnej, chciałabym wspierać rozwój mieszkalnictwa w naszej gminie;",
          subdesc: [],
        },
        {
          desc: "będę postulowała o otwarcie marketu w Dalachowie dla wygody i ekonomiczności życia mieszkańców;",
          subdesc: [],
        },
        {
          desc: "stworzenie możliwości do aktywnego spędzania wolnego czasu poprzez przyspieszenie prac nad budową skateparku w centrum Dalachowa;",
          subdesc: [],
        },
        {
          desc: "jednak priorytetem jest dla mnie podniesienie standardu naszej szkoły poprzez:",
          subdesc: [
            "termomodernizację placówki;",
            "organizację zajęć pozalekcyjnych;",
            "kontynuację procesu zagospodarowania terenu w pobliżu sali OSP z przeznaczeniem na rekreację;",
            "organizowanie czasu wolnego dla dzieci podczas wakacji we współpracy z Gminnym Ośrodkiem Kultury i Sportu w Rudnikach.",
          ],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Głosując na mnie, stawiacie Państwo na… integrację, szacunek dla tradycji i dla drugiego człowieka.",
    },
  },
  {
    img: AnetaSzczesnaKalwak,
    title: "Dalachów 216 - do końca",
    name: "Aneta Szczęsna-Kałwak",
    id: "aneta-szczesna-kalwak",
    description: {
      ageAndEdu:
        "53 lata, mężatka, mama wspaniałej dwójki: Moniki i Michała, rodowita dalachowianka. Z zawodu położna.",
      personal:
        "Radna wsi Dalachów w mijającej kadencji 2018-2024. Wolontariuszka dobrych serc. Radna od trudnych ludzkich spraw.",
      achievementsQuestion: "Gdzie Państwo mogli mnie zobaczyć?",
      achievements: [
        {
          desc: "jestem współzałożycielką i aktywną członkinią Koła Gospodyń Wiejskich w Dalachowie;",
          subdesc: [],
        },
        {
          desc: "czynnie wspierałam realizację projektów na rzecz rozwoju wsi, m.in. Małej Salki w OSP Dalachów, infrastruktury drogowej, nadajnika telefonii komórkowej;",
          subdesc: [],
        },
        {
          desc: "angażowałam się w organizację wielu wydarzeń charytatywnych dla potrzebujących z naszej miejscowości i Gminy;",
          subdesc: [],
        },
        {
          desc: "mając doświadczenie w branży medycznej wspierałam w działaniach reaktywacji lecznicy w Żytniowie;",
          subdesc: [],
        },
        {
          desc: "aktywnie współorganizowałam Dożynki Gminne w Dalachowie.",
          subdesc: [],
        },
      ],
      futurePlans: "Jakie są moje plany na przyszłą kadencję?",
      plans: [
        {
          desc: "będę zabiegała o sukcesywne wprowadzanie w naszej Gminie rozwiązań ułatwiających życie i codzienne funkcjonowanie osób niepełnosprawnych;",
          subdesc: [],
        },
        {
          desc: "dostrzegając potrzeby seniorów będę zabiegać o poszerznie oferty kulturalnej skierowanej właśnie do nich: spotkania, wyjazdy, koncerty, warsztaty, konkursy;",
          subdesc: [],
        },
        {
          desc: "chciałbym kontynuować przyjęty kierunek działań Koła Gospodyń Wiejskich, ze szczególnym uwzględnieniem wolontariatu;",
          subdesc: [],
        },
        {
          desc: "mając na względzie dobro naszych dzieci, widzę konieczność termomodernizacji szkoły podstawowej w Dalachowie i będę bardzo aktywnie wspierała projekt takiej inicjatywy;",
          subdesc: [],
        },
        {
          desc: "zależy mi na otwarciu marketu w Dalachowie, gdyżułatwiłoby to mieszkańcom, a szczególnie naszym seniorom, codzienne życie;",
          subdesc: [],
        },
        {
          desc: "chciałabym aby uruchomiono w naszej Gminie usługę „Mobilny Urzędnik”, aby wesprzeć osoby mające problem z poruszaniem się w załatwianiu spraw urzędowych.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Szanowny Wyborco, zagłosuj na mnie, bo potrzeby w naszych sercach to przyszłość w naszych działaniach.",
    },
  },
  {
    img: DamianTroczka,
    title: "Mirowszczyzna, Mostki, Polesie, Słowików",
    name: "Damian Troczka",
    id: "damian-troczka",
    description: {
      ageAndEdu:
        "42 lata,żonaty, tata Bartka i Tobiasza. Nauczyciel z wykształcenia, policjant z zawodu, strażak z pasji.",
      personal:
        "Miłośnik lokalnej historii, kolekcjoner wspomnień…dla Wspólnej Gminy, dla nas wszystkich.",
      achievementsQuestion: "Moje dotychczasowe społeczne działania:",
      achievements: [
        {
          desc: "od wielu lat działam na rzecz rozwoju swojej lokalnej społeczności oraz OSP w Mostkach, dowodząc determinacji w działaniu, umiejętności pozyskiwania i wykorzystania funduszy oraz skuteczności w realizacji swoich zamierzeń;",
          subdesc: [],
        },
        {
          desc: "pracując we wspólnocie, przyczyniłem się do powstania Sołeckiego Domu Spotkań w Mostkach, który na przestrzeni lat został rozbudowany, zmodernizowany oraz wyposażony w niezbędne i funkcjonalne sprzęty;",
          subdesc: [],
        },
        {
          desc: "jako Prezes OSP w Mostkach zabiegałem o poprawę jakości pracy strażaków oraz bezpieczeństwo mieszkańców, przekazując im 150 czujników czadu.",
          subdesc: [],
        },
      ],
      futurePlans: "Co chciałbym dla Państwa zrobić?",
      plans: [
        {
          desc: "w zgodzie z dotychczasową polityką naszej Gminy zdecydowanie stawiam na budowę i modernizację dróg gminnych oraz rozbudowę oświetlenia ulicznego;",
          subdesc: [],
        },
        {
          desc: "istotna jest dla mnie poprawa bezpieczeństwa w okolicy szkoły w Jaworznie poprzez wybudowanie chodnika przy szkole oraz dodatkowego parkingu;",
          subdesc: [],
        },
        {
          desc: "będę aktywnie zabiegał o zaadaptowanie terenu wokół szkoły w Jaworznie (boisko trawiaste) na nowoczesny i bezpieczny aktywny park do spędzania wolnego czasu przez dzieci, młodzież oraz osoby starsze przez cały rok (np.: trasy rolkowe, górka saneczkowa na zimę, itp.);",
          subdesc: [],
        },
        {
          desc: "wesprę wszelkie oddolne działania służące pozyskiwaniu funduszy na rozwój lokalnych społeczności i ich promocji.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Priorytet w mojej ofercie:",
      slogan:
        "Bezpieczeństwo i troska o innych, ochrona przeciwpożarowa oraz wygodniejsze życie mieszkańców.",
    },
  },
  {
    img: KatarzynaWojtal,
    title: "Jaworzno, Jaworzno Bankowe",
    name: "Katarzyna Wojtal",
    id: "katarzyna-wojtal",
    description: {
      ageAndEdu: "46 lat, mama Oli i Bartka, pracownik administracji w szkole.",
      personal:
        "Od zawsze jaworznianka, po uszy zakochana w ogrodzie. Czarodziejka kwiatów, zatopiona w lekturze przy popołudniowej kawie w altance. Pasjonatka nordicwalking. Przemierzająca rowerem gminne drogi i bezdroża.",
      achievementsQuestion: "W czym Państwu pomagałam jako radna?",
      achievements: [
        {
          desc: "inicjowałam wiele działań wspierających rozwój szkoły i przedszkola w Jaworznie:",
          subdesc: [
            "oświetlenie parkingu;",
            "serduszko do zbierania nakrętek;",
            "pozyskiwanie dofinansowań z GKRPA na dojazdy dzieci na basen do Wielunia i Olesna;",
            "współorganizacja wyjazdu dla dzieci z tutejszej szkoły do Zakopanego na naukę jazdy na nartach oraz wycieczek integracyjnych dla uczniów z pięciu szkół gminy Rudniki nad morze do Międzyzdrojów, Świnoujścia i w góry do Szczyrku;",
            "starania o oddanie do ponownego użytku oddziału przedszkolnego we wschodnim skrzydle placówki;",
          ],
        },
        {
          desc: "zabiegałam o logo na budynku remizy OSP w Jaworznie;",
          subdesc: [],
        },
        {
          desc: "jako członkini Komitetu Organizacji 100-lecia Parafii Jaworzno aktywnie uczestniczyłam w przygotowaniu tego wydarzenia;",
          subdesc: [],
        },
        {
          desc: "podjęłam starania o paczkomat InPost;",
          subdesc: [],
        },
        {
          desc: "skutecznie zabiegałam o poprawę infrastruktury drogowej: doświetlenie i poprawa nawierzchni dróg, lustra drogowe, próg zwalniający w Jaworznie Bankowym;",
          subdesc: [],
        },
        {
          desc: "zainicjowałam i administruję stronę internetową Sołectwa Jaworzno;",
          subdesc: [],
        },
        {
          desc: "działałam na rzecz integracji mieszkańców i promocji Jaworzna: współorganizacja Festynu Rodzinnego, Dnia Kobiet, realizacja projektów sołeckich, publikacja artykułów w Biuletynie Gminy Rudniki.",
          subdesc: [],
        },
      ],
      futurePlans: "Jakie mam plany na kolejną kadencję?",
      plans: [
        {
          desc: "za główny cel kolejnej kadencji stawiam sobie starania o nowy wóz strażacki dla OSP Jaworzno oraz wybrukowanie powstającego tam parkingu;",
          subdesc: [],
        },
        {
          desc: "uważam, że konieczna jest poprawa nawierzchni drogi od posesji nr 127 do 130;",
          subdesc: [],
        },
        {
          desc: "będę dążyć do budowy ścieżki pieszo - rowerowej przy DK 42 w kierunku Parzymiech, przy DK 43 w kierunku Rudnik, a także przy szkole i przedszkolu w Jaworznie i poprawy stanu nawierzchni dróg;",
          subdesc: [],
        },
        {
          desc: "chciałabym w dalszym ciągu reprezentować mieszkańców i angażować się w ich sprawy.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Czy warto oddać na mnie głos?",
      slogan: "Tak... bo siła leży w działaniu, nie w słowach.",
    },
  },
  {
    img: RenataWozniak,
    title: "Julianpol",
    name: "Renata Woźniak",
    id: "renata-wozniak",
    description: {
      ageAndEdu: "50 lat, mama dorosłej córki Dominiki, dyspozytorka.",
      personal:
        "Marzycielka dziś… jutro… może kobieta mors, bo świat przed każdym stoi otworem. Kulinarne wariacje w kuchni, dobry film wieczorem, aromatyczna kawa z przyjaciółką... i rozmowy o przyszłości, bo wszystko, co lepsze, zaczyna się od zmiany, a Państwa głos na mnie to jej początek.",
      achievementsQuestion: "Gdzie mnie Państwo mogli zobaczyć?",
      achievements: [
        {
          desc: "jako kandydatka na radną stawiam pierwsze kroki, jednak od zawsze jestem związana z Julianpolem i jako mieszkanka doskonale znam potrzeby naszej wsi;",
          subdesc: [],
        },
        {
          desc: "zainicjowałam skuteczny remont drogi w Julianpolu w 2021 r.;",
          subdesc: [],
        },
        {
          desc: "aktywnie uczestniczyłam w pracach komisji wyborczej;",
          subdesc: [],
        },
        {
          desc: "działałam na rzecz integracji mieszkańców, szczególnie kobiet.",
          subdesc: [],
        },
      ],
      futurePlans: "Dlaczego kandyduję na radną?",
      plans: [
        {
          desc: "poprawa infrastruktury drogowej w Julianpolu to absolutny priorytet w moich przyszłych działaniach, w szczególności droga „na Karcze”;",
          subdesc: [],
        },
        {
          desc: "będę zabiegała o skanalizowanie Julianpola;",
          subdesc: [],
        },
        {
          desc: "ważną dla mnie kwestią jest oświetlenie miejscowości, podnoszące bezpieczeństwo nas wszystkich;",
          subdesc: [],
        },
        {
          desc: "widzę potrzebę modernizacji placu wiejskiego i utworzenia miejsca spotkań dla mieszkańców.",
          subdesc: [],
        },
      ],
      whyVoteForMe: "Dlaczego zasługuję na Państwa głos?",
      slogan:
        "Drogi Wyborco… nie bój się zmian, bo one otwierają nowe możliwości... Liczę na Państwa głosy – to moc do działania, gdyż razem możemy odnieść sukces. ",
    },
  },
];
