import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";
import { motion } from "framer-motion";

const Button = ({
  type = "button",
  variant,
  className,
  id,
  onClick,
  children = "Button",
}) => {
  let componentClasses = "btn-component";

  if (variant) {
    componentClasses += ` btn-component-${variant}`;
  }

  if (className) {
    componentClasses += ` ${className}`;
  }

  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      type={type}
      className={componentClasses}
      id={id}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
