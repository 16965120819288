import React from "react";

const Donate = () => {
  return (
    <>
      <div
        id="wesprzyj"
        className="flex flex-col items-center p-4 sm:max-w-2xl sm:mx-auto text-center"
      >
        <h1 className="text-2xl font-bold mb-4">Drodzy Przyjaciele,</h1>
        <p className="text-center mb-4">
          Wspólnie kroczymy w stronę przyszłości. Wierzymy, że razem możemy
          dokonać wielkich rzeczy. Pragniemy jutra opartego na wartościach,
          szacunku, uczciwości i równości szans, a najbliższe wybory będą w tej
          kwestii kluczowe dla Gminy Rudniki. Dlatego też zachęcamy Was do
          dołączenia do naszej misji poprzez dobrowolne wsparcie finansowe
          kampanii wyborczej KWW Grzegorza Domańskiego –{" "}
          <strong className="font-bold">WSPÓLNA GMINA</strong>.
        </p>
        <p className="mb-4">
          <br />
          Komitet Wyborczy Wyborców Grzegorza Domańskiego-Wspólna Gmina
        </p>
        <p className="mb-4">
          Nr konta:{" "}
          <strong className="font-bold text-secondary">
            00 0000 0000 0000 0000 0000 0000
          </strong>
        </p>
      </div>
    </>
  );
};

export default Donate;
