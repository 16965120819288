import React from "react";
import Inwestycje from "../../assets/stats/inwestycje.png";
import InwestycjeOswiata from "../../assets/stats/inwestycje_oswiata.png";
import DrogiGminne from "../../assets/stats/drogi_gminne.png";

const About = () => {
  return (
    <div
      id="o mnie"
      className="h-full lg:w-full flex items-center justify-center p-4 lg:justify-evenly lg:pt-8"
    >
      <div className="max-w-md  text-center md:max-w-[90%] lg:max-w-[95%] lg:text-justify">
        <h1 className="text-4xl font-bold mb-4 lg:text-center">O mnie</h1>
        <br />
        <div className="lg:flex lg:w-full">
          <p className="text-sm mb-0 lg:text-lg lg:w-full lg:px-4">
            Nazywam się{" "}
            <strong className="text-secondary"> Grzegorz Domański </strong>. Mam
            59 lat. Gmina Rudniki to moje miejsce na Ziemi, a Dalachów to jego
            bijące serce – miejscowość, w której się urodziłem, wychowałem,
            gdzie mam szczęśliwy dom, cudowną żonę Dorotę i synów, którzy są dla
            nas największą radością i dumą – Piotra i Mikołaja. Z wykształcenia
            jestem nauczycielem matematyki. Moja droga zawodowa rozpoczęła się w
            szkole średniej, w której pracowałem kilkanaście lat, potem byłem
            dyrektorem szkoły podstawowej w Dalachowie. Praca z dziećmi i
            młodzieżą nauczyła mnie pokory wobec ludzkich problemów i hartu w
            ich rozwiązywaniu. Dlatego zaufanie, jakim Państwo mnie obdarzyli,
            pozwoliło mi w 2018 r. rozpocząć kolejną służbę w roli Wójta Gminy
            Rudniki. Starając się wypełnić złożone wtedy Państwu obietnice,
            zabiegając o lepszą dla każdego z nas przyszłość, nigdy nie
            zapomniałem, że jestem mężem, ojcem, sąsiadem... Bardzo lubię
            przejażdżki rowerowe z żoną. Szczególnie te po drogach i bezdrożach
            naszej Gminy. Wtedy mogę spojrzeć na otoczenie nie z perspektywy
            urzędniczego biurka, ale jako jeden z mieszkańców. To cenne
            doświadczenie. Za kilka tygodni kurz wyborczy w Gminie Rudniki
            opadnie i zostanie rzeczywistość, z którą przyjdzie się zmierzyć
            Wójtowi tej gminy.
          </p>
          <p className="text-sm lg:text-lg lg:w-full lg:mb-0 lg:px-4">
            A bycie Wójtem to nie składanie licznych obietnic. To realizowanie
            konkretnego gospodarczego programu. To podejmowanie decyzji często
            trudnych, a czasami wręcz niepopularnych, to niejednokrotnie
            wybieranie mniejszego zła. Kadencja 2018 – 2024 przypadła na bardzo
            trudne czasy pandemii, wojny w Ukrainie i szalejącej inflacji. Mimo
            tych zawirowań udało się zrealizować wiele planów wyborczych, a
            nawet poszerzyć zakres naszych działań o nowe inicjatywy, jak budowa
            żłobka czy dziennego domu pobytu seniorów. Łączny koszt wdrożonych w
            Gminie inwestycji to prawie{" "}
            <strong className="text-secondary"> 65 milionów złotych </strong>, z
            czego ponad{" "}
            <strong className="text-secondary"> 40 milionów złotych </strong>{" "}
            stanowiły dofinansowania. Nasze osiągnięcia są widoczne w całej
            Gminie - od budowy nowych obiektów edukacyjnych, po modernizację
            infrastruktury komunalnej i dbałość o ekologię. Ale to dopiero
            początek. W najbliższej kadencji chciałbym kontynuować przyjęty
            kierunek rozwoju i wprowadzić innowacyjne projekty, takie jak budowa
            nowoczesnej oczyszczalni ścieków czy rozbudowa infrastruktury
            rekreacyjnej. Wójt odpowiada za wszystkie sprawy o znaczeniu
            lokalnym, jego zadaniem jest zaspokajanie potrzeb wszystkich
            mieszkańców całej Gminy. Jest nas niespełna 8 tysięcy, w tym dwie
            grupy wiekowe wymagające szczególnej opieki i wsparcia: dzieci i
            seniorzy. W tym miejscu pozwolę sobie Państwu zaprezentować mój
            program działań na kolejną kadencję.
          </p>
        </div>
        <br />
        <br />
        <div className="lg:flex lg:justify-between lg:w-full">
          <img
            src={Inwestycje}
            alt="inwestycje"
            className="lg:w-[32%] lg:h-1/3"
          ></img>
          <br />
          <br />
          <img
            src={InwestycjeOswiata}
            alt="inwestycje_oswiata"
            className="lg:w-[32%] lg:h-1/3"
          ></img>
          <br />
          <br />
          <img
            src={DrogiGminne}
            alt="drogi_gminne"
            className="lg:w-[32%] lg:h-1/3"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default About;
