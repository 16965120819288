import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "../index";
import { useNavigate } from "react-router-dom";

function ImageGallery({ persons, variant = "up" }) {
  const [activeItem, setActiveItem] = useState(5);
  const wrapperRef = useRef(null);
  const timeoutRef = useRef(null);

  const navigate = useNavigate();

  const navigateToCouncilCandidate = (id) => {
    navigate("/radni", { state: { targetId: `${id}` } });
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the viewport is less than or equal to a certain width (e.g., 768px for mobile)
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check on component mount
    checkIsMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    wrapperRef.current.style.setProperty(
      "--transition",
      "600ms cubic-bezier(0.22, 0.61, 0.36, 1)",
    );

    timeoutRef.current = setTimeout(() => {
      wrapperRef.current?.style.removeProperty("--transition");
    }, 900);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [activeItem]);

  return (
    <div className="flex flex-col grow w-full h-full max-w-full justify-evenly content-evenly px-8 lg:px-12">
      <div
        className={classNames(
          variant === "up" ? "hidden lg:flex" : "hidden lg:hidden",
          "flex-col items-center",
        )}
      >
        <span className="text-2xl font-bold text-center">
          {persons[activeItem].name}
        </span>
        <span className="text-center pb-4">{persons[activeItem].title}</span>
        <Button
          onClick={() => navigateToCouncilCandidate(persons[activeItem].id)}
        >
          Moje plany
        </Button>
      </div>
      <ul
        ref={wrapperRef}
        className="group flex flex-col gap-3 lg:py-4 lg:h-[640px] lg:flex-row lg:gap-[1.5%]"
      >
        {persons.map((person, index) => (
          <li
            onClick={
              isMobile
                ? () => navigateToCouncilCandidate(person.id)
                : () => setActiveItem(index)
            }
            aria-current={activeItem === index}
            className={classNames(
              "relative cursor-pointer lg:w-[8%] lg:[&[aria-current='true']]:w-[48%]",
              "lg:[transition:width_var(--transition,200ms_ease-in)]",
              "lg:before-block before:hidden before:bottom-0 before:left-[-10px] before:right-[-10px] before:top-0 before:bg-white",
              "lg:[&:not(:hover),&:not(:first),&:not(:last)]:group-hover:w-[7%] lg:hover:w-[12%]",
            )}
            key={person.name}
          >
            <div className="relative min-h-[16vh] h-full w-full overflow-hidden rounded-2xl bg-orange-200">
              <img
                className="absolute drop-shadow-2xl right-0 top-1/2 brightness-130 h-full w-24 max-w-none -translate-y-1/2 scale-110 lg:scale-60 xl:scale-80 2xl:scale-100 object-cover object-top lg:left-1/2 lg:h-[640px] lg:w-[400px] lg:-translate-x-1/2"
                src={person.img}
                alt={person.name}
                width="400px"
                height="640px"
              />
              <div
                className={classNames(
                  "lg:hidden inset-0 opacity-25 duration-300 before:hidden before:absolute before:bottom-0 before:left-[-546px] before:right-0 before:top-[-148px] before:z-10 before:bg-gradient-to-r before:from-orange-800 before:to-primary-200 after:bottom-[28px] after:left-0 after:right-[-434px] after:top-0 after:z-10 after:bg-texture lg:absolute lg:transition-opacity",
                  activeItem === index ? "lg:opacity-25" : "lg:opacity-0",
                )}
              />
              <div
                className={classNames(
                  "lg:hidden",
                  "flex flex-col min-h-[13vh] left-8 top-8 w-[590px] p-4 transition-[transform,opacity] lg:absolute lg:p-0",
                  activeItem === index
                    ? "lg:translate-x-0 lg:opacity-100"
                    : "lg:translate-x-4 lg:opacity-0",
                )}
              >
                <p className="text-sm max-w-[30%] max-[350px]:text-[0.7rem] max-[350px]:max-w-[20%] uppercase text-amber-950 font-semibold lg:text-lg">
                  {person.title}
                </p>
                <p className="text-lg max-w-[30%] max-[350px]:max-w-[20%] text-primary font-bold lg:text-4xl">
                  {person.name}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div
        className={classNames(
          variant === "down" ? "hidden lg:flex" : "hidden lg:hidden",
          "flex-col items-center",
        )}
      >
        <span className="text-2xl font-bold text-center">
          {persons[activeItem].name}
        </span>
        <span className="text-center pb-4">{persons[activeItem].title}</span>
        <Button
          onClick={() => navigateToCouncilCandidate(persons[activeItem].id)}
        >
          Moje plany
        </Button>
      </div>
    </div>
  );
}

ImageGallery.propTypes = {
  persons: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.any,
      name: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  variant: PropTypes.string,
};

export default ImageGallery;
