import React from "react";

const Meetings = () => {
  // Dummy data for demonstration purposes
  const meetings = [
    {
      date: "9 marca (sobota)",
      time: "17:00",
      city: "Mostki",
      place: "sala OSP",
    },
    {
      date: "9 marca (sobota)",
      time: "19:00",
      city: "Jaworzno",
      place: "sala OSP",
    },
    {
      date: "10 marca (niedziela)",
      time: "17:00",
      city: "Janinów, Młyny",
      place: "sala - dworzec PKP",
    },
    {
      date: "10 marca (niedziela)",
      time: "19:00",
      city: "Julianpol",
      place: "Młodzieżowy Ośrodek Wychowawczy",
    },
    {
      date: "16 marca (sobota)",
      time: "17:00",
      city: "Cieciułów",
      place: "sala OSP",
    },
    {
      date: "16 marca (sobota)",
      time: "19:00",
      city: "Dalachów",
      place: "sala OSP",
    },
    {
      date: "17 marca (niedziela)",
      time: "15:00",
      city: "Chwiły",
      place: "sala OSP",
    },
    {
      date: "17 marca (niedziela)",
      time: "17:00",
      city: "Jelonki",
      place: "sala OSP",
    },
    {
      date: "17 marca (niedziela)",
      time: "19:00",
      city: "Żytniów",
      place: "sala OSP",
    },
    {
      date: "23 marca (sobota)",
      time: "17:00",
      city: "Łazy",
      place: "domek sołecki",
    },
    {
      date: "23 marca (sobota)",
      time: "19:00",
      city: "Bobrowa",
      place: "sala OSP",
    },
    {
      date: "24 marca (niedziela)",
      time: "17:00",
      city: "Odcinek",
      place: "domek sołecki",
    },
    {
      date: "24 marca (niedziela)",
      time: "19:00",
      city: "Rudniki",
      place: "sala GOKSiR",
    },
  ];

  // Group meetings by date
  const groupedMeetings = meetings.reduce((acc, meeting) => {
    const key = meeting.date;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(meeting);
    return acc;
  }, {});

  return (
    <div
      id="spotkania"
      className="max-w-md mx-auto mt-8 p-4 lg:max-w-[80%] lg:flex lg:flex-col lg:items-center lg:pt-16"
    >
      <h1 className="text-2xl font-bold mb-4 text-center">
        Harmonogram spotkań:
      </h1>
      <p className="text-center lg:w-[60%]">
        <strong className="text-secondary"> Szanowni Mieszkańcy, </strong>
        Wierząc, że rozmowa nie tylko łagodzi obyczaje, ale jest również forum
        otwartym na pomysły i prezentację często różnorodnych opinii,{" "}
        <strong className="text-secondary">
          {" "}
          z przyjemnością zapraszam wszystkich Państwa na serię wyjątkowych
          spotkań{" "}
        </strong>
        , które będą niezwykłą okazją do dyskusji o naszej społeczności i
        poznania wizji jej przyszłości. Spotkania wyborcze są stałym elementem
        każdej kampanii, ale też niepowtarzalną okazją do podsumowania
        dotychczasowych osiągnięć oraz rozmowy o planach i projektach na
        przyszłość, które przez ostatnie 5 lat były przez Państwa zgłaszane.
        Jest to również okazja do wymiany poglądów z kandydatami na radnych
        przyszłej kadencji oraz dotychczasowymi radnymi naszej Gminy.
        Przyjdźcie, posłuchajcie, dyskutujcie – bo wspólnie możemy zdziałać
        naprawdę bardzo wiele!
      </p>
      <br />
      <div className="lg:flex lg:flex-row lg:flex-wrap lg:items-center lg:h-full lg:w-[60%]">
        {Object.entries(groupedMeetings).map(([date, meetings], index) => (
          <div
            key={index}
            className="border border-gray-300 p-4 mb-4 rounded lg:w-full"
          >
            <p className="text-2xl font-bold text-secondary lg:text-center">
              {date}
            </p>
            <br />
            {meetings.map((meeting, innerIndex) => (
              <div
                className="flex justify-between content-between items-center w-full text-center"
                key={innerIndex}
              >
                <p className="text-lg font-extrabold w-[30%]">{meeting.city}</p>
                <p className="text-md w-[50%]">{meeting.place}</p>
                <p className="text-md w-[20%]">{meeting.time}</p>
                <br />
              </div>
            ))}
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};

export default Meetings;
