import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const listItems = ["Program", "O mnie", "Radni", "Spotkania", "Wesprzyj"];
  const navigate = useNavigate(); // From react-router v6

  return (
    <nav className="app__navbar">
      <div className="flex justify-start items-center text-2xl font-semibold lg:text-3xl cursor-pointer">
        <a
          className="flex"
          onClick={() => {
            navigate("/", { state: { targetId: "top" } });
          }}
        >
          <h1 className="text-primary pr-1 ">Wspólna</h1>
          <h1 className="text-secondary">Gmina</h1>
        </a>
      </div>
      <ul className="app__navbar-links">
        {listItems.map((item) => (
          <li key={`link-${item}`}>
            <a
              onClick={() => {
                navigate("/", { state: { targetId: `${item.toLowerCase()}` } });
              }}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            animate={toggle ? { x: [300, 0] } : { x: [0, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {listItems.map((item) => (
                <li key={item}>
                  <Link
                    to={item.toLowerCase()}
                    smooth={true}
                    duration={300}
                    offset={-50}
                    onClick={() => {
                      setToggle(false);
                      navigate("/", {
                        state: { targetId: `${item.toLowerCase()}` },
                      });
                    }}
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
