import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Zlobek from "../../assets/projects/złobek.jpg";
import MieszkanieKomunalne from "../../assets/projects/mieszkanie_komunalne.jpg";
import DomDziennegoPobytu from "../../assets/projects/dom_dziennego_pobytu.jpg";
import Amfiteatr from "../../assets/projects/amfiteatr.jpg";
import Skatepark from "../../assets/projects/skatepark.jpg";

const Programme = () => {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  const variantsStagger = {
    visible: {
      opacity: 1,
      duration: 1,
    },
    hidden: { opacity: 0 },
  };

  const ref = useRef(null);
  const itemsRef = useRef(null);

  const isInView = useInView(ref, { amount: 0.5, once: true });
  const isInViewItems = useInView(itemsRef, { amount: 0.05, once: true });

  return (
    <div
      id="program"
      className="flex flex-col h-full w-full lg:w-full px-4 lg:px-24 pt-8 justify-even items-center lg:pt-[clamp(1rem,10vh,10rem)]"
    >
      <motion.span
        ref={ref}
        animate={isInView ? "visible" : "hidden"}
        initial="hidden"
        transition={{ staggerChildren: 0.05 }}
        className="text-2xl lg:text-[clamp(2em,2vw,10em)] font-semibold text-center"
      >
        {"„Bo liczą się czyny, nie słowa”".split("").map((char, index) => (
          <motion.span
            key={index}
            variants={variants}
            className="text-secondary"
          >
            {char}
          </motion.span>
        ))}{" "}
        – cele programowe:{" "}
      </motion.span>
      <br />
      <div className="flex flex-col h-full w-full justify-even items-center justify-self-center self-center lg:max-w-[80%] lg:leading-relaxed">
        <br />
        <motion.ul
          ref={itemsRef}
          animate={isInViewItems ? "visible" : "hidden"}
          initial="hidden"
          transition={{ staggerChildren: 0.5 }}
          className="list-disc text-sm pl-6 lg:text-[clamp(0.2rem,2vh,10rem)] lg:leading-relaxed"
        >
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Budowa nowoczesnej oczyszczalni ścieków w Rudnikach.{" "}
            </strong>
            <br /> Inwestycja o charakterze strategicznym, niezbędna pod kątem
            rozbudowy sieci kanalizacji w kolejnych miejscowościach gminy – w
            realizacji.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Budowa dziennego domu pobytu seniorów, </strong>
            <br /> z możliwością jego rozbudowy do placówki całodobowej.
            Zapewnienie kompleksowej pomocy społecznej, magazyn żywności,
            zaplecze rehabilitacyjne, pomieszczenia GOPS – w realizacji.
          </motion.li>
          <br />
          <motion.div
            variants={variantsStagger}
            className="lg:flex lg:flex-col lg:w-full lg:items-center"
          >
            <img
              src={DomDziennegoPobytu}
              alt="dom_dziennego_pobytu"
              className="rounded-2xl lg:max-w-[50%]"
            ></img>
            <figcaption className="text-center">
              Wizualizacja domu dziennego pobytu dla seniorów.
            </figcaption>
            <br />
          </motion.div>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Budowa mieszkań komunalnych dedykowanych dla młodych rodzin.{" "}
            </strong>
            <br /> Pilotażowa realizacja w Dalachowie.
          </motion.li>
          <br />
          <motion.div
            variants={variantsStagger}
            className="lg:flex lg:flex-col lg:w-full lg:items-center"
          >
            <img
              src={MieszkanieKomunalne}
              alt="mieszkanie_komunalne"
              className="rounded-2xl lg:max-w-[50%]"
            ></img>
            <figcaption className="text-center">
              Wizualizacja mieszkań komunalnych.
            </figcaption>
            <br />
          </motion.div>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Doposażenie kolejnych jednostek OSP w wozy strażackie i sprzęt
              ratowniczo-gaśniczy.{" "}
            </strong>
            <br /> W realizacji zakup ciężkiego samochodu ratowniczo-gaśniczego
            dla OSP Rudniki i średniego dla OSP Jaworzno. W perspektywie
            doposażenie kolejnych jednostek.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Utworzenie punktu ratownictwa medycznego z karetką pogotowia w
              Rudnikach{" "}
            </strong>
            (adaptacja pomieszczeń po GOPS).
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Budowa i remont dróg gminnych i powiatowych. </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Systematyczna budowa ścieżek pieszo-rowerowych </strong>
            zapewniających bezpieczeństwo mieszkańców i skomunikowanie terenu
            gminy. Obecnie realizowany jest pierwszy etap budowy ścieżek
            rowerowych Faustianka – Rudniki oraz Rudniki – Jaworzno.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Oświetlenie dróg. </strong>
            <br /> W realizacji kompleksowa modernizacja oświetlenia ulicznego,
            obejmująca wymianę wszystkich opraw na energooszczędne ledowe oraz
            montaż 390 dodatkowych lamp.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Budowa kompleksu rekreacyjnego wraz z amfiteatrem w Młynach{" "}
            </strong>{" "}
            (w trakcie realizacji).
            <br /> Stworzenie miejsca wypoczynku dla mieszkańców z
            wykorzystaniem istniejących walorów przyrodniczych.
          </motion.li>
          <br />
          <motion.div
            variants={variantsStagger}
            className="lg:flex lg:flex-col lg:w-full lg:items-center"
          >
            <img
              src={Amfiteatr}
              alt="amfiteatr"
              className="rounded-2xl lg:max-w-[50%]"
            ></img>
            <figcaption className="text-center">
              Wizualizacja amfiteatru w Młynach.
            </figcaption>
            <br />
          </motion.div>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Zagospodarowanie terenów po byłej cegielni w Faustiance.{" "}
            </strong>
            <br /> Utworzenie centrum konferencyjno-szkoleniowego z zachowaniem
            historycznego charakteru tego miejsca, w tym odrestaurowanie
            unikatowego pieca.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Termomodernizacja szkoły w Dalachowie, </strong>
            obejmująca wymianę stolarki okiennej, modernizację pokrycia
            dachowego i docieplenie ścian zewnętrznych.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Kompleksowa modernizacja kuchni i stołówki w szkole podstawowej w
              Rudnikach.{" "}
            </strong>
            <br /> Realizacja zadania usprawni przygotowywanie posiłków dla
            szkoły, przedszkola i żłobka oraz dla nowo powstającego dziennego
            domu pobytu seniorów. W perspektywie możliwość rozszerzenia oferty
            dla mieszkańców.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Modernizacja obiektów sportowych z ich oświetleniem.{" "}
            </strong>
            <br /> Budowa skateparku z pumptruckiem w Dalachowie - w realizacji.
            Zadaszenie lodowiska w Rudnikach.
          </motion.li>
          <br />
          <motion.div
            variants={variantsStagger}
            className="lg:flex lg:flex-col lg:w-full lg:items-center"
          >
            <img
              src={Skatepark}
              alt="zlobek"
              className="rounded-2xl lg:max-w-[50%]"
            ></img>
            <figcaption className="text-center">
              Wizualizacja skateparku w Dalachowie.
            </figcaption>
            <br />
          </motion.div>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Adaptacja pomieszczeń przy ulicy Targowej na potrzeby GOKSiR w
              Rudnikach wraz z zagospodarowaniem terenu i utworzeniem
              dodatkowych miejsc parkingowych.{" "}
            </strong>
            <br /> Stworzenie nowego miejsca spotkań dla dzieci, młodzieży,
            seniorów, klubów zainteresowań, kół, itp.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Rozbudowa żłobka „Skrzacik” w Rudnikach, </strong>
            zapewniająca miejsca dla wszystkich zgłoszonych dzieci. Przewidywane
            zakończenie prac: wrzesień 2024 r.
          </motion.li>
          <br />
          <motion.div
            variants={variantsStagger}
            className="lg:flex lg:flex-col lg:w-full lg:items-center"
          >
            <img
              src={Zlobek}
              alt="zlobek"
              className="rounded-2xl lg:max-w-[50%]"
            ></img>
            <figcaption className="text-center">
              Wizualizacja żłobka w Rudnikach.
            </figcaption>
            <br />
          </motion.div>
          <motion.li variants={variantsStagger}>
            <strong> Budowa windy w GOKSiR w Rudnikach </strong>
            <br /> (w trakcie realizacji) oraz dostosowanie infrastruktury
            gminnej dla potrzeb osób z niepełnosprawnościami i seniorów.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Sukcesywna modernizacja strażnic, budynków LZS-ów i infrastruktury
              sołeckiej.{" "}
            </strong>
          </motion.li>
          <br />
          <strong className="text-lg"> Ponadto: </strong>
          <br />
          <motion.li variants={variantsStagger}>
            <strong> Mobilny urzędnik </strong>– umożliwienie załatwienia spraw
            urzędowych w miejscu zamieszkania (senior, osoby z
            niepełnosprawnościami).
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Opracowanie planu zagospodarowania przestrzennego Gminy,{" "}
            </strong>
            z uwzględnieniem aktualizacji zalesienia oraz wyznaczenia terenów
            inwestycyjnych i mieszkaniowych.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Zabieganie o wybudowanie marketów w większych miejscowościach.{" "}
            </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Wsparcie mieszkańców poprzez finansowanie z programu parasolowego
              klastra energii instalacji fotowoltaicznych oraz wymiany źródeł
              ciepła.{" "}
            </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Poszerzenie oferty zajęć pozalekcyjnych </strong>
            dla dzieci z wykorzystaniem infrastruktury szkolnej.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Kontynuacja oferty bezpłatnych zajęć nauki pływania dla dzieci.{" "}
            </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Aktywizacja działalności organizacji pozarządowych, stowarzyszeń i
              grup formalnych poprzez zwiększone wsparcie finansowe.{" "}
            </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong> Wzmocnienie działań promujących Gminę </strong>
            poprzez wykorzystanie walorów historycznych, przyrodniczych i
            kulturowych:
            <motion.ul className="list-disc ml-8 text-justify">
              <motion.li>
                odtworzenie historycznego punktu granicznego,
              </motion.li>
              <motion.li>wieża widokowa w Jaworku,</motion.li>
              <motion.li>
                punkt turystyczny na styku trzech województw – trójstyk,
              </motion.li>
              <motion.li>
                „Ocalić od zapomnienia” – seniorzy dzieciom.
              </motion.li>
            </motion.ul>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Utworzenie małego obserwatorium astronomicznego w szkole
              podstawowej w Jaworznie.{" "}
            </strong>
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Wymiana dzieci i młodzieży o zasięgu lokalnym, ogólnopolskim i
              międzynarodowym{" "}
            </strong>
            – wyjazdy o charakterze edukacyjnym i integracyjnym.
          </motion.li>
          <motion.li variants={variantsStagger}>
            <strong>
              {" "}
              Aktywizacja seniorów poprzez organizację cyklicznych spotkań
              tematycznych.{" "}
            </strong>
          </motion.li>
        </motion.ul>
      </div>
    </div>
  );
};

export default Programme;
