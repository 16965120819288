import {
  About,
  CouncilCandidates,
  Donate,
  Header,
  Meetings,
  ProgrammeSection,
} from "../../container";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Home() {
  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  return (
    <div className="app">
      <Header />
      <About />
      <ProgrammeSection />
      <CouncilCandidates />
      <Meetings />
      <Donate />
    </div>
  );
}

export default Home;
