import React from "react";

const Footer = () => {
  return (
    <div className="bg-gray-900 text-white h-[8vh] font-light text-sm flex items-center justify-center">
      <p>&copy; 2024 Wspólna Gmina. All rights reserved.</p>
    </div>
  );
};

export default Footer;
