import React from "react";
import { Programme } from "../../components";

const ProgrammeSection = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col lg:flex-row w-screen justify-center lg:w-full"
    >
      <Programme />
    </div>
  );
});

ProgrammeSection.displayName = "ProgrammeSection";

export default ProgrammeSection;
