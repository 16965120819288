import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.scss";

import { Navbar } from "./components";
import Home from "./pages/Home/Home";
import CouncilCandidatesPage from "./pages/CouncilCandidatesPage/CouncilCandidatesPage";
import { Footer } from "./container";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/radni" element={<CouncilCandidatesPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
