import React from "react";
import { motion } from "framer-motion";

import "./Header.scss";
import { HeroImage } from "../../constants/images";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate(); // From react-router v6

  return (
    <>
      <div id="top" className="h-[7vh]"></div>
      <div id="home" className="app__header app__flex">
        <motion.div
          animate={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.75 }}
          className="app__header-info"
        >
          <span className="head-text">
            BO LICZĄ SIĘ <span>CZYNY,</span> NIE SŁOWA
          </span>
          <h1 className="p-text">
            Twój głos - nasza moc zmian. Odkryj, jak razem budujemy lepszą
            przyszłość.
          </h1>
          <p className="hidden lg:flex lg:font-semibold lg:text-3xl lg:text-right lg:italic lg:font-['Courgette'] lg:text-secondary lg:justify-center">
            Grzegorz Domański{" "}
          </p>
          <p className="hidden lg:flex lg:text-2xl lg:text-amber-50 lg:font-bold lg:justify-center">
            Kandydat na wójta Gminy Rudniki
          </p>
          <span className=""></span>
          <div className="head-btns">
            <Button
              id="head-btn"
              onClick={() => {
                navigate("/", { state: { targetId: "program" } });
              }}
            >
              Program
            </Button>
            <Button
              id="head-btn"
              onClick={() => {
                navigate("/", { state: { targetId: "radni" } });
              }}
            >
              Radni
            </Button>
          </div>
        </motion.div>
        <div className="app__header-hero">
          <img src={HeroImage} alt="hero_image" />
        </div>
      </div>
      <motion.div className="flex flex-col h-[35vh] items-center justify-center border-t-4 border-secondary pb-8 md:hidden ">
        <motion.span
          animate={{ x: [-400, 0], opacity: [0, 1] }}
          transition={{ duration: 0.75, delay: 0.75 }}
          className="text-3xl w-5/6 font-semibold text-center italic font-['Courgette']"
        >
          {" "}
          Bo liczą się <span className="text-secondary">czyny,</span> nie słowa
        </motion.span>
        <motion.span
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 0.75, delay: 1.5 }}
          className="text-l pt-4 w-5/6 font-semibold text-right italic font-['Courgette'] text-secondary"
        >
          {" "}
          Grzegorz Domański
        </motion.span>
        <motion.span
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 0.75, delay: 2 }}
          className="text-s w-5/6 font-light text-right italic font-['Courgette']"
        >
          {" "}
          Kandydat na wójta Gminy Rudniki
        </motion.span>
      </motion.div>
    </>
  );
};

export default Header;
