import React, { useEffect } from "react";
import { persons } from "../../components/ImageGallery/persons";
import { useLocation } from "react-router-dom";

function CouncilCandidatesPage() {
  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  return (
    <div className="bg-app-bg font-var(--font-base) lg:flex lg:flex-col lg:items-center">
      {persons.map((person) => (
        <div
          key={person.id}
          id={person.id}
          className="flex flex-col items-center w-screen h-full lg:w-[70%]"
        >
          <div className="h-1 w-4/6 bg-amber-600"></div>

          <div className="h-[8vh]"></div>
          <img
            width="400px"
            height="640px"
            src={person.img}
            alt={person.name}
            className="w-3/6 rounded-2xl lg:max-h-[20%] lg:max-w-[20%]"
          />
          <div className="flex flex-col h-full w-11/12 items-center text-center">
            <br />
            <span className="font-bold text-xl">{person.name}</span>
            <p className="text-secondary font-semibold">{person.title}</p>
            <br />
            <span className="font-semibold xl:w-4/6">
              {person.description.ageAndEdu}
            </span>
            <br className="hidden xl:block" />
            <span className="w-5/6 text-center lg:w-4/6">
              {person.description.personal}
            </span>
            <br />
            <p className="font-bold">
              {person.description.achievementsQuestion}
            </p>
            <br />
            <ul className="list-disc w-5/6 text-justify xl:w-4/6">
              {person.description.achievements.map((achievement, index) => (
                <React.Fragment key={`${achievement.length}-${index}`}>
                  <li key={achievement.id}>{achievement.desc}</li>
                  {achievement.subdesc.length ? (
                    <ul
                      key={`sub-${achievement.id}-${index}`}
                      className="list-decimal ml-8 text-justify"
                    >
                      {achievement.subdesc.map((sub, subIndex) => (
                        <li key={`${achievement.id}-${index}-${subIndex}`}>
                          {sub}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <br />
            <p className="font-bold">{person.description.futurePlans} </p>
            <br />
            <ul className="list-disc w-5/6 text-justify xl:w-4/6">
              {person.description.plans.map((plan, index) => (
                <React.Fragment key={`${plan.length}-${index}`}>
                  <li key={plan.id}>{plan.desc}</li>
                  {plan.subdesc.length ? (
                    <ul className="list-decimal ml-8 text-justify">
                      {plan.subdesc.map((sub, subIndex) => (
                        <li key={`${sub.id}-${sub}-${subIndex}`}>{sub}</li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <br />
            <p className="font-bold">{person.description.whyVoteForMe}</p>
            <br />
            <p className="w-5/6">{person.description.slogan}</p>
            <br />
            <br />
          </div>
        </div>
      ))}
    </div>
  );
}

export default CouncilCandidatesPage;
