import React from "react";
import { persons } from "../../components/ImageGallery/persons";

import { ImageGallery } from "../../components";

const CouncilCandidates = React.forwardRef((props, ref) => {
  const midpoint = Math.ceil(persons.length / 2);

  const [firstHalf, secondHalf] = [
    persons.slice(0, midpoint),
    persons.slice(midpoint),
  ];

  return (
    <div
      ref={ref}
      className="flex flex-col h-full w-screen justify-center items-center content-stretch lg:w-full lg:h-screen"
    >
      <div className="h-[8vh]"></div>
      <span id="radni" className="text-2xl font-bold text-center">
        Poznaj kandydatów na radnych!
      </span>
      <span className="text-center w-5/6 lg:hidden">
        (Kliknij na kandydata aby dowiedzieć się więcej)
      </span>
      <div className="flex flex-col lg:flex-row grow h-4/5 w-screen lg:w-full pt-8 justify-center content-center">
        <div className="flex flex-col flex-1">
          <ImageGallery persons={firstHalf} />
        </div>
        <div className="flex flex-col flex-1 pt-4 lg:pt-0">
          <ImageGallery persons={secondHalf} variant="down" />
        </div>
      </div>
    </div>
  );
});

CouncilCandidates.displayName = "CouncilCandidates";

export default CouncilCandidates;
